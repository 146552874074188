import { container, primaryColor } from "assets/jss/material-kit-react.jsx";

const footerStyle = {
  block: {
    color: "#fff",
    padding: "0.9375rem",
    fontWeight: "500",
    fontSize: "12px",
    textTransform: "uppercase",
    borderRadius: "3px",
    textDecoration: "none",
    position: "relative",
    display: "block",
    marginTop: "2rem",
    "@media (max-width: 900px)": {
      marginTop: "1rem",
    }
  },
  left: {
    float: "left!important",
    display: "block"
  },
  right: {
    padding: "15px 0",
    margin: "0",
    color: "#fff",
    float: "right",
    "@media (max-width: 600px)": {
      float: "none",
    }
  },
  talign: {
    textAlign: "right",
    color: "#fff",
    fontSize: "14px",
    marginTop: "1rem",
    "@media (max-width: 600px)": {
      textAlign: "center",
    }
  },
  taligntwo: {
    textAlign: "center",
    color: "#fff",
    fontSize: "10px",
    marginTop: "1rem",
    "@media (max-width: 600px)": {
      textAlign: "center",
    }
  },
  footer: {
    padding: "0.9375rem 0",
    textAlign: "center",
    display: "flex",
    zIndex: "2",
    position: "relative"
  },
  a: {
    color: primaryColor,
    textDecoration: "none",
    backgroundColor: "transparent"
  },
  footerWhiteFont: {
    "&,&:hover,&:focus": {
      color: "#FFFFFF"
    }
  },
  container,
  list: {
    marginBottom: "0",
    padding: "0",
    marginTop: "0"
  },
  inlineBlock: {
    display: "inline-block",
    padding: "0px",
    width: "auto"
  },
  notinlineBlock: {
    display: "block",
    padding: "0px",
    width: "auto"
  },
  icon: {
    width: "18px",
    height: "18px",
    position: "relative",
    top: "3px"
  },
  imagewrap: {
    marginRight: "10px",
  },
  partnerlogo: {
    maxHeight: "60px",
    maxWidth: "150px",
    marginBottom: "2rem",
    marginTop: "1rem",
    "@media (max-width: 900px)": {
      maxHeight: "50px",
    }
  },
  aClasses: {
    color: "#fff",
    fontSize: "15px",
  },
  aClassestwo: {
    color: "#fff",
    fontSize: "10px",
  }
};
export default footerStyle;
