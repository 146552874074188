/*eslint-disable*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import { Link } from "gatsby";
import classNames from "classnames";
import { List, ListItem, withStyles } from "@material-ui/core";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import vcdlogo from "assets/img/vcdlogo.png";
import calogo from "assets/img/calogo.png";
import wclogo from "assets/img/wclogo.png";
import AniLink from "gatsby-plugin-transition-link/AniLink";

// @material-ui/icons
import Favorite from "@material-ui/icons/Favorite";

import footerStyle from "assets/jss/material-kit-react/components/footerStyle.jsx";

function Footer({ ...props }) {
  const { classes, whiteFont } = props;
  const footerClasses = classNames({
    [classes.footer]: true,
    [classes.footerWhiteFont]: whiteFont
  });
  const aClasses = classNames({
    [classes.a]: true,
    [classes.footerWhiteFont]: whiteFont
  });
  return (
    <footer className={footerClasses}>
      <div className={classes.container}>
      <GridContainer>
      <GridItem xs={12} sm={6} md={6}>
        <div className={classes.left}>
          <List className={classes.list}>
            <ListItem className={classes.inlineBlock}>
            <AniLink className={classes.block} cover direction="down" duration={2} to="/location" bg="linear-gradient(60deg, #0F2B41 40%, #192637 80%)">
            Location
            </AniLink>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
            <AniLink className={classes.block} cover direction="down" duration={2} to="/specifications" bg="linear-gradient(60deg, #0F2B41 40%, #192637 80%)">
            Specifications
            </AniLink>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
            <AniLink className={classes.block} cover direction="down" duration={2} to="/gallery-page" bg="linear-gradient(60deg, #0F2B41 40%, #192637 80%)">
            Gallery
            </AniLink>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
            <AniLink className={classes.block} cover direction="down" duration={2} to="/contact" bg="linear-gradient(60deg, #0F2B41 40%, #192637 80%)">
            Contact
            </AniLink>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
            <div className={classes.talign}>
          &copy; {1900 + new Date().getYear()} , 
          <a
            href="https://www.virtuouscircledevelopments.co.uk"
            className={classes.aClasses}
            target="_blank"
          >
            <span> </span>
            Virtuous Circle Developments
          </a>{" "}
          </div>
            </ListItem>
            <ListItem className={classes.notinlineBlock}>
            <div className={classes.taligntwo}>
          Designed and developed by
          <a
            href="https://thepixelconnoisseur.com/"
            className={classes.aClassestwo}
            target="_blank"
          >
            <span> </span>
            The Pixel Connoisseur
          </a>{" "}
          </div>
            </ListItem>
          </List>
        </div>
        </GridItem>
        <GridItem xs={12} sm={4} md={4}>
        <div className={classes.right}>
        <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
          <div className={classes.imagewrap}>
          <img alt="..." src={calogo} className={classes.partnerlogo} />
          </div>
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
          <div className={classes.imagewrap}>
          <img alt="..." src={wclogo} className={classes.partnerlogo} />
          </div>
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
          <div className={classes.imagewrap}>
          <img alt="..." src={vcdlogo} className={classes.partnerlogo} />
          </div>
            </GridItem>
            </GridContainer>
        </div>
        </GridItem>
        </GridContainer>
      </div>
    </footer>
  );
}

Footer.propTypes = {
  classes: PropTypes.object.isRequired,
  whiteFont: PropTypes.bool
};

export default withStyles(footerStyle)(Footer);
